import React, { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { IconCheck, IconPlus } from '@tabler/icons-react'
import Utils from 'Utils'

import {
  Avatar,
  Column,
  Modal,
  Row,
  SearchInput,
  Text,
  Tooltip,
} from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import * as Styled from './styles'

interface MergeProfilesModalProps {
  isOpen: boolean
  communityUser?: MainSchema.CommunityUser
  onClose: () => void
}

// TODO: setup for demo purposes - waiting for backend
const MergeProfilesModal = ({
  isOpen,
  communityUser,
  onClose,
}: MergeProfilesModalProps) => {
  const t = useScopedI18n('features.mergeProfiles')

  const [currentStep, setCurrentStep] = useState<1 | 2>(1)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [selectedToMerge, setSelectedToMerge] = useState<string[]>([])

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value)
  }, [])

  const handleAddToMerge = useCallback((id: string) => {
    setSelectedToMerge(prev =>
      prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id],
    )
  }, [])

  const handleRemoveFromMerge = useCallback((id: string) => {
    setSelectedToMerge(prev => prev.filter(item => item !== id))
  }, [])

  const handleOnClose = useCallback(() => {
    setSelectedToMerge([])
    setSearchQuery('')
    setCurrentStep(1)
    onClose()
  }, [onClose])

  const handleBack = useCallback(() => {
    setCurrentStep(1)
  }, [])

  const handleMergeSelected = useCallback(() => {
    if (currentStep === 1) {
      setCurrentStep(2)
    } else {
      // TODO: merge logic
      handleOnClose()
    }
  }, [handleOnClose, currentStep])

  const suggestedTeamMembers = useMemo(
    () => [
      {
        id: '1',
        name: 'Jane Smyth',
        email: 'jane.smith@example.com',
        photoUrl: '',
      },
      {
        id: '2',
        name: 'Janet Smithe',
        email: 'j.smith@example.org',
        photoUrl: '',
      },
      {
        id: '3',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '4',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '5',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '6',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '7',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '8',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '9',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
      {
        id: '10',
        name: 'Jeanne Smythe',
        email: 'janesmith@example.net',
        photoUrl: '',
      },
    ],
    [],
  )

  const profileAnchorName = useMemo(() => {
    return Utils.User.getFullName(communityUser)
  }, [communityUser])
  const photoUrl = communityUser?.photoUrl

  return (
    <Modal
      cancelText={currentStep === 1 ? t('cancel') : t('back')}
      confirmDisabled={currentStep === 1 ? !selectedToMerge.length : false}
      confirmText={currentStep === 1 ? t('mergeSelected') : t('confirm')}
      glyphIcon={<Styled.MergeIcon />}
      isOpen={isOpen}
      width="400px"
      onClose={currentStep === 1 ? handleOnClose : handleBack}
      onConfirm={handleMergeSelected}
    >
      {currentStep === 1 ? (
        <Column gap={3}>
          <Text header2>{t('title')}</Text>
          <Text bodyMedium color="text.body" mb={2}>
            {t('subtitle')}
          </Text>

          <Row gap={2} wrapped>
            {communityUser && (
              <Styled.ProfileTokenItem isAnchor>
                <Avatar extraSmall src={photoUrl} />
                <Text>{profileAnchorName}</Text>
              </Styled.ProfileTokenItem>
            )}
            {selectedToMerge.map((id: string) => {
              const profile = suggestedTeamMembers.find(
                member => member.id === id,
              )
              return (
                <Tooltip content={profile?.name}>
                  <Styled.ProfileTokenItem
                    onClick={() => handleRemoveFromMerge(id)}
                  >
                    <Avatar extraSmall src={profile?.photoUrl} />
                    <Text>{profile?.name}</Text>
                    <Styled.ProfileTokenClose size={16} />
                  </Styled.ProfileTokenItem>
                </Tooltip>
              )
            })}
          </Row>

          <Styled.Divider />

          <Styled.SearchWrapper>
            <SearchInput
              placeholder={t('searchPlaceholder')}
              value={searchQuery}
              onChange={handleSearchChange}
              onClear={() => setSearchQuery('')}
            />
            {searchQuery && (
              <Styled.DropdownContainer>
                <Text bodySmall color="text.body" p={2}>
                  {t('searchResults')}
                </Text>
                <Styled.ScrollContainer>
                  {suggestedTeamMembers
                    .filter(
                      member =>
                        member.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        member.email
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    )
                    .map(member => (
                      <Styled.ProfileItem key={member.id}>
                        <Avatar small src={member.photoUrl} />
                        <Column>
                          <Text bold>{member.name}</Text>
                          <Text bodyMedium color="text.body">
                            {member.email}
                          </Text>
                        </Column>

                        <Styled.AddButton
                          primary={selectedToMerge.includes(member.id)}
                          secondary={!selectedToMerge.includes(member.id)}
                          onClick={() => handleAddToMerge(member.id)}
                        >
                          {selectedToMerge.includes(member.id) ? (
                            <IconCheck size={16} />
                          ) : (
                            <IconPlus size={16} />
                          )}
                        </Styled.AddButton>
                      </Styled.ProfileItem>
                    ))}
                  {searchQuery &&
                    !suggestedTeamMembers.some(
                      member =>
                        member.name
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        member.email
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()),
                    ) && (
                      <Text bodyMedium color="text.body" p={3}>
                        {t('noSearchResults')}
                      </Text>
                    )}
                </Styled.ScrollContainer>
              </Styled.DropdownContainer>
            )}
          </Styled.SearchWrapper>

          <Text header3 mt={3}>
            {t('suggestedTitle')}
          </Text>

          <Styled.ScrollContainer>
            {suggestedTeamMembers.length ? (
              suggestedTeamMembers.map(member => (
                <Styled.ProfileItem key={member.id}>
                  <Avatar small src={member.photoUrl} />
                  <Column>
                    <Text bold>{member.name}</Text>
                    <Text bodyMedium color="text.body">
                      {member.email}
                    </Text>
                  </Column>

                  <Styled.AddButton
                    primary={selectedToMerge.includes(member.id)}
                    secondary={!selectedToMerge.includes(member.id)}
                    onClick={() => handleAddToMerge(member.id)}
                  >
                    {selectedToMerge.includes(member.id) ? (
                      <IconCheck size={16} />
                    ) : (
                      <IconPlus size={16} />
                    )}
                  </Styled.AddButton>
                </Styled.ProfileItem>
              ))
            ) : (
              <Text bodyMedium color="text.body" mb={2}>
                {t('noSuggestedMatchesFound')}
              </Text>
            )}
          </Styled.ScrollContainer>
        </Column>
      ) : (
        currentStep === 2 && (
          <Column gap={3}>
            <Text header2>{t('confirmMergeTitle')}</Text>
            <Text bodyMedium color="text.body">
              {t('confirmMergeDescription')}
            </Text>
          </Column>
        )
      )}
    </Modal>
  )
}

export default MergeProfilesModal
